<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <div class="d-flex align-center mb-2">
        <v-btn class="shadow-off" small text v-if="pid" @click="$nova.gotoLink({path: '/earnout/project/view/'+pid})"><v-icon left>mdi-arrow-left</v-icon> Back to Project</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="shadow-off" small text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
      </div>

      <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="pb-0">
          <div class="d-flex">
            <div>
              <div class="mb-2">
                <v-chip v-if="earnout.stage === 2" small label color="success">Completed</v-chip>
                <v-chip v-else-if="earnout.stage === 0 && project.stage !== 3" small label color="info">Pending</v-chip>
                <v-chip v-else-if="project.stage !== 3" small label color="warning">Ongoing</v-chip>
                <v-chip v-else-if="project.stage === 3" small label color="error">Suspended</v-chip>
              </div>
              <div class="py-1 d-flex align-center">
                <div>
                  <div class="caption mb-n1">Target Company</div> 
                  <div class="subtitle-1 mt-0 font-weight-bold">{{(earnout.project_detail || {}).targetcompany || ''}}</div> 
                </div>
                <div class="px-4">
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </div>
                <div>
                  <div class="caption mb-n1">Buyer Company</div> 
                  <div class="subtitle-1 mt-0 font-weight-bold">{{(earnout.project_detail || {}).buyercompany || ''}}</div> 
                </div>
              </div>
              <div class="d-flex align-center caption">
                <div class="subtitle-1 font-weight-bold mr-2">{{earnout.stagename}}</div>
                <v-chip small label class="mr-2">{{$nova.formatDate(earnout.start)}}</v-chip>
                -
                <v-chip small label class="ml-2">{{$nova.formatDate(earnout.end)}}</v-chip>
              </div>
              <div class="mt-2">
                <v-chip label small outlined color="" class="pl-0 pt-0">
                  <v-chip color="" label small class="mr-2 caption">Network:</v-chip>
                  {{(earnout.project_detail || {}).network}} 
                </v-chip>
                <v-chip label small outlined color="" class="pl-0 ml-2">
                  <v-chip color="" label small class="mr-2 caption">Region:</v-chip>
                  {{(earnout.project_detail || {}).region}} 
                </v-chip>
                <v-chip label small outlined color="" class="pl-0 ml-2">
                  <v-chip color="" label small class="mr-2 caption">Country:</v-chip>
                  {{(earnout.project_detail || {}).country}} 
                </v-chip>
                <v-chip label small outlined color="" class="pl-0 ml-2">
                  <v-chip color="" label small class="mr-2 caption">Currency:</v-chip>
                  {{(earnout.project_detail || {}).currency}} 
                </v-chip>
              </div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-card-text>
          <div v-if="earnout.stage === 0" class="text-center pa-8">
            <div class="text-h5 grey--text mb-4">Earnout not started yet</div>
            <v-btn small color="primary" v-if=" project.stage !== 3" @click.stop="startAssignment()"><v-icon left>mdi-play</v-icon> Start Earnout Procedure</v-btn>
          </div>
          <div v-else class="">
            <div v-if="!libraryuploaded && !isreviewer">
              <v-alert type="info" :value="true" text border="left" class="py-2">
                Waiting to freeze the AUP Library by the Reviewer.
              </v-alert>
            </div>
            <div v-else class="d-flex align-center">
              <v-tabs v-model="tab" background-color="transparent" height="40" class="" >
                <v-tab class="text-transform-none" key="0">AUP Library</v-tab>
                <v-tab v-if="earnout.stage >= 1 && libraryuploaded" class="text-transform-none" key="2">OPAT Computation</v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn v-if="earnout.stage === 1 && project.stage !== 3 && isreviewer" color="error" @click="closedialog=true" small class="shadow-off"><v-icon class="mr-1">mdi-close</v-icon>Close Earnout</v-btn>
              <v-btn v-if="earnout.stage === 2 && project.stage !== 3 && isreviewer" color="success" @click="open()" small class="shadow-off"><v-icon class="mr-1">mdi-close</v-icon>Re-open Earnout</v-btn>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="libraryuploaded && Object.keys(runs).length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="isaddedit || isreviewer" class="ml-2" v-bind="attrs" v-on="on" color="" @click="pptdialog=true" icon><v-icon class="">mdi-presentation</v-icon></v-btn>
                  <v-btn v-else class="ml-2" v-bind="attrs" v-on="on" color="" @click="getAssignmentResult('final')" icon><v-icon class="">mdi-presentation</v-icon></v-btn>
                </template>
                <span>PPT Result</span>
              </v-tooltip>

            </div>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab">
              <v-tab-item key="0">
                <v-card class="shadow-off">
                  <v-card-text class="px-0">
                    <v-btn color="primary" v-if="libraryuploaded" small class="mb-2" @click="$nova.downloadFile(id, {}, '/v2/earnout/assignment/gettopsheettemplate')">Download Top Sheet Template</v-btn>
                    <div class="mb-2" v-if="earnout.stage === 1 && project.stage !== 3 && !libraryuploaded">
                      <div class="">AUP Library is not yet uploaded. Please upload the same to start earnout.</div>
                      <div class="mt-2 d-flex align-center">
                        <v-btn color="primary" small class="mr-2" @click="$nova.downloadFile(id, {}, '/v2/earnout/assignment/getauplibrarytemplate')">Download AUP Library Template</v-btn>
                        <lb-file v-model="auplibraryfile" label="" class="py-0 my-0"
                          :loading="fileprocessing['__auplibraryfile'] === 1"  :drag="false" hidedetails @change="readAUPLibrary('__auplibraryfile')" :displayname="false"
                          accept=".xlsx,.xls,.xlsm,.xlsb" :disabled="earnout.stage !== 1 || project.stage === 3"
                        />
                      </div>
                    </div>
                    <div v-if="libraryuploaded">
                      <v-slide-y-transition class="" group tag="div" style="overflow:hidden !important;">
                        <div v-for="(v,k) in formattedaupdata" :key="k" class="">
                          <div v-if="v.type==='category'" class="pa-2 font-weight-bold body-2 border-left-ctransaction" :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.ctransaction, 0.1)}`">{{v.name}}</div>
                          <template v-else>
                            <div class="hoverable">
                              <div class="d-flex">
                                <div class="flex-grow-1">
                                  <template v-for="(pv,pk) in v.aupdata">
                                    <div :key="pk">
                                      <div class="d-flex">
                                        <div style="width:100px" class="flex-shrink-0 px-2 py-1 font-weight-bold">AUP {{pv.aupnumber}}</div>
                                        <div class="flex-grow-1 px-2 py-1" style="white-space: break-spaces;" v-html="pv.description"></div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div class="pa-2 flex-shrink-0" style="width:200px">
                                  <div class="text-center" v-if="isaddedit">
                                    <div v-if="(runs[v._id] || {}).stage === 2">
                                      <div v-if="(runs[v._id] || {}).review === 0">
                                        <v-menu offset-y transition="slide-y-transition" v-if="isreviewer">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-chip v-bind="attrs" v-on="on" small class="mb-1" color="warning" label>Pending Review <v-icon class="ml-1">mdi-chevron-down</v-icon></v-chip>
                                          </template>
                                          <v-list dense class="pa-0 border-left-default">
                                            <v-list-item @click.stop="changeAUPStatus((runs[v._id] || {})._id, 'approve')">
                                              <v-list-item-title class="success--text"><v-icon left class="success--text">mdi-check</v-icon>Approve</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="changeAUPStatus((runs[v._id] || {})._id, 'reject')">
                                              <v-list-item-title class="error--text"><v-icon left class="error--text">mdi-close</v-icon>Reject</v-list-item-title>
                                            </v-list-item>
                                          </v-list>
                                        </v-menu>
                                        <v-chip v-else small class="mb-1" color="warning" label>Pending Review</v-chip>
                                      </div>
                                      <v-chip v-else-if="(runs[v._id] || {}).review === 1" small class="" color="success" label>Completed</v-chip>
                                      <v-chip v-else-if="(runs[v._id] || {}).review === -1" small class="" color="error" label>Rejected</v-chip>
                                      <br v-if="!isreviewer"/>
                                      <v-btn v-if="isaddedit" text small @click="resultkey=v._id;resultdialog=true" class="shadow-off"><v-icon class="mr-1">mdi-eye</v-icon> View</v-btn>
                                    </div>
                                    <v-chip v-else-if="[0,1].indexOf((runs[v._id] || {}).stage) > -1" small class="" color="warning" label>Processing</v-chip>
                                    <v-chip v-else-if="(runs[v._id] || {}).stage === -1 && (runs[v._id] || {}).errorstack" small class="" color="error" label  @click.stop="showError(((runs[v._id] || {}).errorstack || ''))">Error</v-chip>
                                    <v-chip v-else-if="(runs[v._id] || {}).stage === -1" small class="" color="error" label>Error</v-chip>
                                    <v-chip v-else small class="" color="info" label>Pending</v-chip>
                                  </div>
                                  <div class="d-flex justify-center">
                                    <lb-file v-model="files[v._id]" label="" :id="((runs[v._id] || {}).upload_detail || {})._id"
                                      :loading="fileprocessing[v._id] === 1" :loadingpercent="(Number(fileprocessingpercent[v._id]) || 0) + (uploadcounter*0)" 
                                      :drag="false" hidedetails @change="uploadFile(v._id)" :displayname="false"
                                      accept=".xlsx,.xls,.xlsm,.xlsb" :disabled="earnout.stage !== 1 || project.stage === 3"
                                    />
                                    <v-tooltip bottom content-class="tooltip-bottom" v-if="((runs[v._id] || {}).upload_detail || {})._id">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="shadow-off" fab text x-small @click="getLogs(v._id)">
                                          <v-icon class="">mdi-history</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Activity Log</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <v-divider></v-divider>
                        </div> 
                      </v-slide-y-transition>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="2" v-if="earnout.stage >= 1 && libraryuploaded">
                <div class="d-flex align-center mt-4">
                  <lb-file label="" @change="uploadOPATFile('__opat')" 
                    v-model="opatfileupload" :loadingpercent="(Number(fileprocessingpercent['__opat']) || 0) + (uploadcounter*0)"
                    :loading="fileprocessing['__opat'] === 1" :drag="false" accept=".xlsx" :displayname="true" hidedetails
                    :id="opatfile._id || ''"
                  />
                  <v-chip class="ml-4" v-if="!opatfile._id" color="info" small label>Not yet Uploaded</v-chip>
                  <span v-else>{{opatfile.uploadname}}</span>
                </div>
                <v-simple-table dense class="mt-4 opat_table">
                  <template v-slot:default>
                    <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                      <tr v-for="(v,k) in opatdata" :key="k">
                        <template v-for="(ov,ok) in v">
                          <td v-if="ok !== 'tag'" :class="`px-2 opat_tag_${(v.tag || 'blank')} ${(typeof ov === 'number')?'text-right':''}`" :key="ok">{{(typeof ov === "number")?$nova.formatNumber(ov):ov}}</td>
                        </template>
                      </tr>
                    </v-slide-y-transition>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
            
          </div>
        </v-card-text>
      </v-card>

      <v-dialog v-model="logdialog" max-width="600" persistent>
        <v-card :loading="logloading" :disabled="logloading" loader-height="2" class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Activity Log</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="logdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-0 mx-0">
            <perfect-scrollbar style="max-height: 70vh" v-if="logdata.length > 0">
              <div v-for="(v,k) in logdata" :key="k" class="hoverable">
                <div class="px-4 py-3">
                  <div class="d-flex align-center">
                    <v-icon v-if="v.type === 'upload'" color="primary">mdi-cloud-upload-outline</v-icon>
                    <v-icon v-else-if="v.type === 'approve'" color="success">mdi-check-circle-outline</v-icon>
                    <v-icon v-else-if="v.type === 'reject'" color="error">mdi-close-circle-outline</v-icon>
                    <v-icon v-else-if="v.type === 'process'" color="warning">mdi-progress-check</v-icon>
                    <span small label color="success" class="ml-4">{{v.description}}</span>
                    <span class="ml-4">{{$nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "")}}</span>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
            </perfect-scrollbar>
            <div v-else class="title grey--text pa-8 text-center">
              No activity log available
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="resultdialog" max-width="1200" persistent>
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Excel Upload Data</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="resultdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
                <perfect-scrollbar style="max-height: 70vh">          
                  <div class="mt-4">
                    <v-simple-table dense class="">
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="(v,k) in ((runs[resultkey] || {}).result || {}).code || {}" :key="k">
                              <td class="pa-2 flex-shrink-0">{{v}}</td>
                              <td class="pa-2" v-if="typeof (((runs[resultkey] || {}).result || {}).value || {})[k] === 'object'">
                                <v-simple-table dense class="">
                                  <template v-slot:default>
                                    <thead>
                                    <tr class="">
                                      <th class="text-left" id="text" v-for="(ov, ok) in Object.keys((((runs[resultkey] || {}).result || {}).value || {})[k])" :key="ok">{{ov || "aasdff"}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(ov, ok) in getResultTableData((((runs[resultkey] || {}).result || {}).value || {})[k])" :key="ok">
                                        <td v-for="(kov, kok) in (ov || [])" :key="kok">{{kov}}</td>
                                      </tr> 
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </td>
                              <td class="pa-2" v-else style="white-space: break-spaces;" v-html="(((runs[resultkey] || {}).result || {}).value || {})[k] || ''"></td>
                          </tr>
                          <tr v-for="(ov, ok) in getResultTableData((((runs[resultkey] || {}).result || {}).value || {})[k])" :key="ok">
                            <td v-for="(kov, kok) in (ov || [])" :key="kok">{{kov}}</td>
                          </tr> 
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </perfect-scrollbar>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errordialog" max-width="1200" persistent>
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Error Stack</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="errordialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div class="" style="white-space: break-spaces;">
              {{errorstack}}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="closedialog" max-width="600" persistent>
        <v-card :loading="closeloading" :disabled="closeloading" loader-height="2" class="">
          <v-card-text class="pt-6">
            <div class="title mb-4">Confirm!</div>
            <div>
              This will close the earnout and the you will not be able to perform any changes to the earnout.<br/>
              <span class="font-weight-bold">Do you want to proceed?</span>
            </div>
          </v-card-text>
          <v-card-text class="text-right">
            <v-btn small @click="closedialog = false" class="">Cancel</v-btn>
            <v-btn small class="ml-2" color="error" @click="close()">Proceed</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="verifyauplibrarydialog" max-width="600" persistent>
        <v-card :loading="verifyauplibraryloading" :disabled="verifyauplibraryloading" loader-height="2" class="">
          <v-card-text class="pt-6">
            <div class="title mb-4">Confirm!</div>
            <div>
              Once the AUP Library is uploaded, it cannot be modified.<br/>
              <span class="font-weight-bold">Do you want to proceed?</span>
            </div>
          </v-card-text>
          <v-card-text class="text-right">
            <v-btn small @click="verifyauplibrarydialog = false" class="">No</v-btn>
            <v-btn small class="ml-2" color="success" @click="uploadAUPLibrary()">Yes</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="auplibrarydialog" persistent>
        <v-card class="">
          <v-card-text class="pt-6">
            <div class="d-flex align-center mb-4">
              <div class="title">AUP Library to upload</div>
              <div v-if="auplibraryerrorflag" class="ml-4 error--text">The errors highlighted in red color needs to be rectified.</div>
              <v-spacer></v-spacer>
              <v-btn small class="ml-2" color="primary" v-if="!auplibraryerrorflag" @click="verifyauplibrarydialog=true">Verify & Upload</v-btn>
              <v-btn icon @click="auplibrarydialog = false" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <perfect-scrollbar style="max-height: 70vh" v-if="auplibrarydata.length > 0">
              <div>
                <v-simple-table dense class="">
                  <template v-slot:default>
                    <thead>
                      <th style="width:150px" class="text-left flex-shrink-0 pa-1">Group</th>
                      <th style="width:150px" class="text-left flex-shrink-0 pa-1">Category</th>
                      <th style="width:100px" class="text-left flex-shrink-0 pa-1">AUP Number</th>
                      <th style="white-space: break-spaces;" class="text-left flex-grow-1 pa-1">Description</th>
                    </thead>
                  </template>
                </v-simple-table> 
                <v-slide-y-transition class="" group tag="div" style="overflow:hidden !important;">
                  <div v-for="(v,k) in auplibrarydata" :key="k" class="">
                    <template v-if="v.type!=='category'">
                      <div class="d-flex">
                        <div style="width:150px" class="flex-shrink-0 pa-1">{{v.group}}</div>
                        <div>
                          <template v-for="(pv,pk) in v.aupdata">
                            <div class="d-flex" :key="pk">
                              <div style="width:150px" v-if="((auplibraryerror[k] || [])[pk] || [])[0] !== ''" class="flex-shrink-0 pa-1 error--text font-weight-bold">
                                <v-tooltip bottom content-class="tooltip-bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">{{pv.category || 'Error'}}</div>
                                  </template>
                                  <span>{{((auplibraryerror[k] || [])[pk] || [])[0]}}</span>
                                </v-tooltip>
                              </div>
                              <div style="width:150px" class="flex-shrink-0 pa-1" v-else>{{pv.category}}</div>
                              <div style="width:100px" v-if="((auplibraryerror[k] || [])[pk] || [])[1] !== ''" class="flex-shrink-0 pa-1 error--text font-weight-bold">
                                <v-tooltip bottom content-class="tooltip-bottom">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">AUP {{pv.aupnumber}}</div>
                                  </template>
                                  <span>{{((auplibraryerror[k] || [])[pk] || [])[1]}}</span>
                                </v-tooltip>
                              </div>
                              <div style="width:100px" class="flex-shrink-0 pa-1" v-else>AUP {{pv.aupnumber}}</div>
                              <div style="white-space: break-spaces;" v-if="((auplibraryerror[k] || [])[pk] || [])[2] !== ''" class="flex-grow-1 pa-1 error--text font-weight-bold">
                                <v-tooltip left content-class="tooltip-left">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">{{pv.description || 'Error'}}</div>
                                  </template>
                                  <span>{{((auplibraryerror[k] || [])[pk] || [])[2]}}</span>
                                </v-tooltip>
                              </div>
                              <div v-else class="flex-grow-1 pa-1" style="white-space: break-spaces;" v-html="pv.description"></div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                    <v-divider></v-divider>
                  </div> 
                </v-slide-y-transition>
              </div>
            </perfect-scrollbar>  
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="pptdialog" max-width="600">
        <v-card :loading="pptloading" :disabled="pptloading" loader-height="2" class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">PPT Result</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="pptdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pt-4">
            <v-alert v-if="isprocessing" type="info" :value="true" border="left" dense text>
              You can download PPT after all the AUP files are processed
            </v-alert>
            <div v-else>
              <div>
                <span>Download Generated PPT</span> 
                <v-tooltip bottom content-class="tooltip-bottom" v-if="id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="ml-1 shadow-off" fab text x-small @click="getAssignmentResult()">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </div>
              <div class="d-flex align-center">
                <lb-file label="Upload Final PPT" @change="uploadPPTFiles('finalppt', 'final')"
                  v-model="pptfiles['finalppt']" :loadingpercent="(Number(fileprocessingpercent['__pptfinalppt']) || 0) + (uploadcounter*0)"
                  :drag="false" accept=".pptx" :displayname="false" hidedetails
                  :id="earnout.finalppt"
                />
                <v-spacer></v-spacer>
                <div class="pl-2">
                  <v-chip v-if="earnout.finalppt" small color="success" label>Uploaded</v-chip>
                  <v-chip v-else small color="info" label>Pending</v-chip>
                </div>
              </div>
            </div>
            <v-divider class="my-2"></v-divider>
            <template v-for="(v,k) in ['first', 'last']">
              <div class="d-flex align-center" :key="k">
                <lb-file :label="`Upload ${v} static slides`" @change="uploadPPTFiles(v)" 
                  v-model="pptfiles[v]" :loadingpercent="(Number(fileprocessingpercent['__ppt'+v]) || 0) + (uploadcounter*0)"
                  :drag="false" accept=".pptx" :displayname="false" hidedetails
                  :id="(earnout.pptstaticfiles || {})[v]"
                />
                <v-spacer></v-spacer>
                <div class="pl-2">
                  <v-chip v-if="(earnout.pptstaticfiles || {})[v]" small color="success" label>Uploaded</v-chip>
                  <v-chip v-else small color="info" label>Pending</v-chip>
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

export default {
  name: 'earnout_assignment_view',
  data: function(){
    return {
      id: "",
      pid: "",
      project: {},
      earnout: {},
      runs: [],
      upload: {},
      loading: false,
      aupcounter: 1,
      uploadcounter: 0,
      tab: 0,
      isreviewer: false,
      isaddedit: false,
      files: {},
      fileprocessing: {},
      fileprocessingpercent: {},
      procedure: {},
      group: {},
      category: {},
      categoryorder: [],
      grouporder: {}, 
      selectedaup: [],
      aupnumbers: {},
      logdialog: false,
      logloading: false,
      logdata: [],
      completionstage: 2,
      closedialog: false,
      closeloading: false,
      errorstack: "",
      errordialog: false,
      resultdialog: false,
      resultkey: -1,
      lastruntime: null,
      opatfile: {},
      opatfileupload: null,
      opatdataheader: [],
      opatdata: [],
      customaup: {},
      pptdialog: false,
      pptloading: false,
      pptfiles: {},
      libraryuploaded: false,
      auplibraryfile: null,
      auplibrarydata: [],
      auplibraryerror: [],
      auplibraryerrorflag: false,
      auplibrarydialog: false,
      verifyauplibraryloading: false,
      verifyauplibrarydialog: false,
      isprocessing: false,
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  mounted() {
  },
  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      let perm = (((this.$store.state.user || {}).permission || {}).earnout_assignment || [])
      this.isreviewer = perm.indexOf('review') > -1
      if(perm.indexOf('add') > -1 || perm.indexOf('edit') > -1) this.isaddedit = true;
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/earnout/project/getearnoutdetails/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.earnout = dt.data.data[0] || {};
          this.project = this.earnout.project_detail || {};
          this.pid = this.project._id || "";
          this.auplibrarydata = this.earnout.aups || [];
          if(this.auplibrarydata.length > 0) this.libraryuploaded = true;
          else this.libraryuploaded = false;
          this.opatfile = this.earnout.opatexcelfile_detail || {};
          this.opatdataheader = [];
          this.opatdata = [];

          for (const i of (this.earnout.opatexceldata || [])) {
            this.opatdata.push(i);
            for (const k in i) {
              if (Object.hasOwnProperty.call(i, k)) {
                if(this.opatdataheader.indexOf(k) === -1) this.opatdataheader.push(k)
              }
            }
          }
          this.runs = this.earnout.run_detail || {};
          this.lastruntime = null;
          this.isprocessing = false;
          for (const k in this.runs) {
            if (Object.hasOwnProperty.call(this.runs, k)) {
              const el = this.runs[k];
              if(el.stage === 0 || el.stage === 1) this.isprocessing = true;
              if(!this.lastruntime) this.lastruntime = el.updated_at || el.created_at;
              else this.lastruntime = Math.max(el.updated_at || el.created_at, this.lastruntime);
            }
          }
        }else throw new "error reading earnout details";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    readAUPLibrary(key){
      let file = this.auplibraryfile;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        this.pptloading = true;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(['Category', 'Code', 'Description', 'Group'])){
            let grouplist = [];
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (el[3] || "").toString();
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
              }
            }
            let groupdata = {};
            let grouporder = [];
            let errordata = {};
            this.auplibraryerrorflag = false;
            let gstartnum = 1;
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (el[3] || "").toString();
                if(gname === ""){
                  let newgname = "g"+(gstartnum || "").toString();
                  while(grouplist.indexOf(newgname) !== -1) {
                    newgname = "g"+(gstartnum || "").toString();
                    gstartnum = gstartnum+1;
                  }
                  gname = newgname;
                  if(grouplist.indexOf(gname) === -1) grouplist.push(gname);
                  el[3] = gname;
                }
                groupdata[gname] = groupdata[gname] || [];
                groupdata[gname].push({"group": gname, "category": el[0], "aupnumber": el[1], "description": el[2]});
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
                if(grouporder.indexOf(gname) === -1) grouporder.push(gname)
                errordata[gname] = errordata[gname] || [];
                let errors = ["","",""];
                if((el[0] || "").toString() === "") errors[0] = "Category is required";
                else if(groupdata[gname].length > 0) {
                  if((el[0] || "").toString() !== groupdata[gname][0].category) errors[0] = "Group items should have same category";
                }
                if((el[1] || "").toString() === "") errors[1] = "AUP Number is required";
                else if(isNaN(Number(el[1] || 0))) errors[1] = "AUP Number has to be a number type";
                if((el[2] || "").toString() === "") errors[2] = "Description is required";
                if(errors.join("") !== "") this.auplibraryerrorflag = true;
                errordata[gname].push(errors);
              }
            }
            this.auplibrarydata = [];
            this.auplibraryerror = [];
            for (const i of grouporder) {
              this.auplibrarydata.push({'group': i, aupdata: groupdata[i]})
              this.auplibraryerror.push(errordata[i]); 
            }
            this.auplibrarydialog = true;
          }else throw new Error("Columns 'Category','Code','Description','Group' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.auplibraryfile = null;
          this.fileprocessing[key] = 0;
          this.pptloading = false;
        });
      }
    },
    uploadAUPLibrary(){
      this.verifyauplibraryloading = true;
      this.axios.post("/v2/earnout/analysis/addauplibrary/"+this.id, {data: this.auplibrarydata}).then(dt => {
        if(dt.data.status === "success"){
          this.verifyauplibrarydialog = false;
          this.auplibrarydialog = false;
          this.getData();
          this.$store.commit("sbSuccess", "AUP library uploaded successfully");
        }else throw new dt.data.message || "Error uploading AUP library";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.verifyauplibraryloading = false;
      })
    },
    showError(err="No Message"){
      if(this.$store.state.isadmin){
        this.errordialog=true;
        this.errorstack=err;
      }else{
        this.errorstack="No Data";
      }
    },
    getLogs(k) {
      this.logdialog = true;
      this.logloading = true;
      this.logdata = [];
      this.axios.post("/v2/earnout/assignment/readlogs/"+this.id+"/g/"+k).then(dt => {
        if(dt.data.status === "success"){
          this.logdata = dt.data.data;
          this.logloading = false;
        }else throw new "Error loading log data";
      }).catch(err => {
        this.logdialog = false;
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.logloading = false;
      })
    },
    uploadPPTFiles: function(key, type="static"){
      let file = this.pptfiles[key];
      if((file || {}).name && (this.fileprocessing["__ppt"+key] || 0) === 0){
        this.fileprocessing["__ppt"+key] = 1;
        this.pptloading = true;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["pptx"].indexOf(ext) === -1) throw new "Only pptx file extension supported";
          return this.$nova.uploadFile(this.axios, file, {
            progressfunction: (e) => {
              try{
                let p = e.loaded*100/e.total;
                this.fileprocessingpercent['__ppt'+key] = p.toFixed(0);
                this.uploadcounter = this.uploadcounter + 0.01;
              }catch(e){
                console.log(e);
                throw new e;
              }
            }
          })
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            let data = {upload: dt.data.data[0]._id, key: key};
            let url = "/v2/earnout/assignment/storestaticppt/"+this.id
            if(type==="final") url = "/v2/earnout/assignment/storefinalppt/"+this.id
            return this.axios.post(url, {data: [data]});
          }else throw new "Unable to upload file";
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.refreshData();
            this.$store.commit("sbSuccess", "PPT file uploaded");
          }else throw new "error uploading file";
        }).catch(err => {
          this.pptfiles[key] = null;
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.fileprocessing["__ppt"+key] = 0;
          this.pptloading = false;
        });
      }
    },
    uploadOPATFile: function(key){
      let file = this.opatfileupload;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx"].indexOf(ext) === -1) throw new "Only xlsx file extension supported";
          return this.$nova.uploadFile(this.axios, file, {
            progressfunction: (e) => {
              try{
                let p = e.loaded*100/e.total;
                this.fileprocessingpercent[key] = p.toFixed(0);
                this.uploadcounter = this.uploadcounter + 0.01;
                console.log(p, this.uploadcounter);
              }catch(e){
                console.log(e);
                throw new e;
              }
            }
          })
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            let data = {upload: dt.data.data[0]._id};
            return this.axios.post("/v2/earnout/assignment/storeopatexcel/"+this.id, {data: [data]});
          }else throw new "Unable to upload file";
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.refreshData();
            this.$store.commit("sbSuccess", "OPAT file uploaded");
          }else throw new "error uploading file";
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.fileprocessing[key] = 0;
          this.opatfileupload = null;
        });
      }
    },
    uploadFile: function(group){
      let file = this.files[group];
      let ext = ((file || {}).name || "").split(".").pop();
      if((file || {}).name && (this.fileprocessing[group] || 0) === 0){
        this.fileprocessing[group] = 1;
        Promise.resolve().then(() => {
          if(["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx/xls/xlsm/xlsb file extension supported";
          return this.$nova.uploadFile(this.axios, file, {
            progressfunction: (e) => {
              try{ 
                let p = e.loaded*100/e.total;
                this.fileprocessingpercent[group] = p.toFixed(0);
                this.uploadcounter = this.uploadcounter + 0.01;
              }catch(e){
                console.log(e);
              }
            }
          })
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.fileprocessing[group] = 2;
            let data = {upload: dt.data.data[0]._id, project: this.pid, assignment: this.id, group: group};
            // console.log(data);
            return this.axios.post("/v2/earnout/assignment/createrun", {data: [data]});
          }else throw new "Unable to upload file";
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.$store.commit("sbSuccess", "File uploaded");
            this.fileprocessing[group] = 0;
            this.refreshData();
          }else throw new "error uploading file";
        }).catch(err => {
          this.fileprocessing[group] = 0;
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.files[group] = null;
        })
      }
    },
    getAssignmentResult(type="none") {
      this.loading = true;
      this.axios.post("/v2/earnout/assignment/getassignmentresult/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){
            let data = dt.data.data[0] || {};
            if(type === 'final' && data.finalppt) this.$nova.downloadFile(data.finalppt);
            else if(data.stage === 0 || data.stage === 1) this.$store.commit("sbSuccess", "File is being generated. Try downloading after sometime");
            else if(data.stage === -1) this.$store.commit("sbError", "There was an error while generating PPT");
            else if(!data.upload) this.$store.commit("sbError", "No file found to download");
            else this.$nova.downloadFile(data.upload);
          }else throw new "no data found to download";
        }else throw new dt.data.message || "Error getting file";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    changeAUPStatus(analysis, type) {
      this.loading = true;
      this.axios.post("/v2/earnout/assignment/changeaupstage/"+this.id+"/a/"+analysis, {data: [{type: type}]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Status Updated");
          this.refreshData();
        }else throw new dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    startAssignment() {
      this.loading = true;
      this.axios.post("/v2/earnout/assignment/start/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Started");
          this.earnout.stage = 1;
        }else throw new dt.data.message || "Error starting earnout";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    close() {
      this.closeloading = true;
      this.axios.post("/v2/earnout/assignment/close/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Closed");
          this.earnout.stage = 2;
          this.closedialog = false;
          this.tab = 1;
        }else throw new dt.data.message || "Error closing earnout";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.closeloading = false;
      })
    },
    open() {
      this.loading = true;
      this.axios.post("/v2/earnout/assignment/open/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Re-opened");
          this.earnout.stage = 1;
        }else throw new dt.data.message || "Error re-opening earnout";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    getResultTableData(d){
      let res = [];
      for (const k in d) {
        if (Object.hasOwnProperty.call(d, k)) {
          const el = d[k];
          let i = 0;
          for (const kk in el) {
            if (Object.hasOwnProperty.call(el, kk)) {
              const kel = el[kk];
              res[i] = res[i] || [];
              res[i].push(kel);
              i++;
            }
          }
        }
      }
      return res;
    },
  },
  computed: {
    formattedaupdata: function(){
      let dta = [];
      let catdata = {};
      for (let i = 0; i < (this.auplibrarydata || []).length; i++) {
        const el = this.auplibrarydata[i];
        let cat = (el.aupdata[0] || {}).category;
        catdata[cat] = catdata[cat] || [];
        catdata[cat].push(el);
      }
      for (const k in catdata) {
        if (Object.hasOwnProperty.call(catdata, k)) {
          const el = catdata[k];
          dta.push({type: 'category', name: k});
          dta = [...dta, ...el];
        }
      }
      return dta;
    },
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
  }
}
</script>

<style lang="scss" scoped>
.opat_table *{
  font-size: 0.8rem !important;
}
.opat_tag_heading{
  font-weight: bold;
  background-color: rgb(31,78,120);
  color: rgb(255,255,255);
  text-align: center;
}
.opat_tag_sub_heading_1{
  font-weight: bold;
  background-color: rgb(217,225,242);
}
.opat_tag_sub_heading_2{
  font-weight: bold;
}
.opat_tag_sub_heading_3{
  font-weight: bold;
  font-style: italic;
}
.opat_tag_blank{
  height: 10px !important;
}
</style>